<template>
  <div>
    <div class="pa-5">
      <h1>Artikelhistorie</h1>
    </div>
    <div class="d-flex flex-wrap">
      <div class="header-input">
        <date-picker :dense="true" :outlined="true" :filled="false" :value="fromDate" label="Datum"
          :dateFormatted="fromDateFormatted" @changeDate="changeFromDate"></date-picker>
      </div>
      <div class="header-input">
        <date-picker :dense="true" :outlined="true" :filled="false" :value="toDate" label="t/m"
          :dateFormatted="toDateFormatted" @changeDate="changeToDate"></date-picker>
      </div>
      <div class="header-input-small">
        <v-select :items="years" v-model="year" label="Jaar" outlined></v-select>
      </div>
      <div class="header-input-small">
        <v-select :items="quarters" v-model="quarter" label="Kwartaal" outlined></v-select>
      </div>
      <div class="header-input-small">
        <v-select :items="months" item-text="text" item-value="value" v-model="month" label="Maand" outlined></v-select>
      </div>
      <div class="header-input">
        <v-select :items="categories" v-model="selectedCategory" label="Categorie" outlined></v-select>
      </div>
      <div class="header-input">
        <search-item :searchString="searchItemString" @setSearchString="setSearchItemString" @get-results="setItem"
          @clear-input="clearItem" :outlined="true"></search-item>
      </div>
      <div class="header-input">
        <search-client :searchString="searchClientString" :noResults="false" :filled="false"
          @setSearchString="setSearchClientString" @get-results="setClient" @clear-input="clearClient"></search-client>
      </div>
      <div class="header-input">
        <v-text-field outlined label="Lotnummer" v-model="searchLotNumber">
        </v-text-field>
      </div>
      <div class="d-flex">
        <div class="stats">Initiële bestelling: {{ toDouble(inAmount) }}</div>
        <div class="stats">
          Actuele bestelling: {{ toDouble(currentAmount) }}
        </div>
        <div class="stats">
          Gemiddelde prijs: {{ toCurrency(averagePrice) }}
        </div>
        <div class="stats">Omzet: {{ toCurrency(revenue) }}</div>
      </div>
      <div class="d-flex align-self-center">
        <button @click="fetchItemsSalesHistory">Verzenden</button>
        <button @click="reset">Reset</button>
      </div>
      <div class="full-width">
        <v-data-table :headers="itemHeaders" :items="items" :server-items-length="itemsLength" :options.sync="options"
          @update:options="fetchItemsSalesHistory">
          <template v-slot:[`item.date`]="{ item }">
            {{ toDateString(item.date) }}
          </template>
          <template v-slot:[`item.client.name`]="{ item }">
            <a class="font-weight-bold" @click="selectClient(item.client)">{{
              item.client.name
              }}</a>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <a class="font-weight-bold" @click="selectItem(item)">{{
              item.name
              }}</a>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <v-edit-dialog @open="findOrder(item)" :return-value.sync="item.amount" cancel-text="Annuleren"
              save-text="Opslaan" large persistent @save="updateOrderItem(thisItem, item.amount, false)">
              {{ item.amount }}
              <template v-slot:input>
                <v-text-field @focus="$event.target.select()" v-model.number="item.amount"
                  label="Aantal"></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            {{ toCurrency(item.price) }}
          </template>
          <template v-slot:[`item.netSubtotal`]="{ item }">
            {{ toCurrency(item.netSubtotal) }}
          </template>
          <template v-slot:[`item.grossSubtotal`]="{ item }">
            {{ toCurrency(item.grossSubtotal) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon @click="downloadOrder(item)">mdi-download</v-icon>
          </template>
        </v-data-table>
      </div>
      <edit-item-modal v-if="editItemModal" :order="thisOrder" :client="thisClient" :editItemModal="editItemModal"
        :item="JSON.parse(JSON.stringify(thisItem))" @update-order-item="updateOrderItem"
        @delete-order-item="deleteOrderItem" @close-item="closeEditItemModal"></edit-item-modal>
    </div>
  </div>
</template>
<script>
import datePicker from "@/components/datePicker.vue"
import searchClient from "@/components/searchClient.vue"
import searchItem from "@/components/searchItem.vue"
import editItemModal from "@/components/editItemModal.vue"
import { fetchGET, toCurrency, toDateString, fetchPOST, errorHandler, successHandler, toDouble } from "../js/functions"
import Big from "big.js"
import { downloadInvoice, downloadOrder } from "../js/pdfController"
export default {
  name: "itemsSalesHistory",
  data() {
    return {
      fromDate: new Date(Date.now()).toISOString().split("T")[0],
      toDate: new Date(Date.now()).toISOString().split("T")[0],
      fromDateFormatted: this.formatDate(
        new Date().toISOString().split("T")[0]
      ),
      toDateFormatted: this.formatDate(new Date().toISOString().split("T")[0]),
      selectedClient: {},
      selectedItem: {},
      searchClientString: "",
      searchItemString: "",
      itemHeaders: [
        { text: "Datum", value: "date" },
        { text: "Ordernr", value: "orderNumber" },
        { text: "Lotnr", value: "lotNumber" },
        { text: "Klant", value: "client.name" },
        { text: "Artikelnr", value: "itemNumber" },
        { text: "Artikel", value: "name" },
        { text: "Aantal", value: "amount" },
        { text: "Eenheid", value: "unit" },
        { text: "Inhoud", value: "unitAmount" },
        { text: "Prijs", value: "price" },
        { text: "BTW", value: "VAT" },
        { text: "Subtotaal", value: "netSubtotal" },
        { text: "Totaal incl.", value: "grossSubtotal" },
        { text: "", value: "actions", width: 110, sortable: false }
      ],
      items: [],
      itemsLength: 0,
      options: {
        itemsPerPage: 50,
        page: 1,
        sortBy: ["date"],
        sortDesc: [true]
      },
      searchLotNumber: "",
      editItemModal: false,
      thisOrder: {},
      thisClient: {},
      thisItem: { name: "", amount: 0 },
      year: null,
      month: null,
      quarter: null,
      selectedCategory: "",
      years: [
        2019,
        2020,
        2021,
        2022,
        2023,
        2024,
        2025
      ],
      quarters: [
        1, 2, 3, 4
      ],
      months: [
        { text: "Januari", value: 1 },
        { text: "Februari", value: 2 },
        { text: "Maart", value: 3 },
        { text: "April", value: 4 },
        { text: "Mei", value: 5 },
        { text: "Juni", value: 6 },
        { text: "Juli", value: 7 },
        { text: "Augustus", value: 8 },
        { text: "September", value: 9 },
        { text: "Oktober", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],
      inAmount: 0,
      currentAmount: 0,
      averagePrice: 0,
      revenue: 0
    }
  },
  methods: {
    toDouble(value) {
      return toDouble(value)
    },
    toDateString(date) {
      return toDateString(date)
    },
    toCurrency(value) {
      return toCurrency(value)
    },
    changeFromDate(date) {
      this.fromDate = date
      this.fromDateFormatted = this.formatDate(date)
      if (this.fromDate > this.toDate) {
        this.toDate = this.fromDate
        this.toDateFormatted = this.fromDateFormatted
      }
    },
    changeToDate(date) {
      this.toDate = date
      this.toDateFormatted = this.formatDate(date)
      if (this.toDate < this.fromDate) {
        this.fromDate = this.toDate
        this.fromDateFormatted = this.toDateFormatted
      }
    },
    formatDate(date) {
      return date
        .split("-")
        .reverse()
        .join("-")
    },
    async fetchItemsSalesHistory() {
      let response = await fetchGET("fetchItemsSalesHistory", {
        fromDate: this.fromDate,
        toDate: this.toDate,
        year: this.year,
        month: this.month,
        quarter: this.quarter,
        category: this.selectedCategory,
        clientNumber: this.selectedClient.clientNumber,
        itemNumber: this.selectedItem.itemNumber,
        lotNumber: this.searchLotNumber,
        options: this.options
      })
      this.items = response[0].results.length ? response[0].results : []
      this.itemsLength = response[0].length.length ? response[0].length[0].length : 0
      this.inAmount = response[0].inAmount.length ? response[0].inAmount[0].inAmount : 0
      this.currentAmount = response[0].currentAmount.length ? response[0].currentAmount[0].currentAmount : 0
      this.averagePrice = response[0].averagePrice.length ? response[0].averagePrice[0].averagePrice : 0
      this.revenue = response[0].revenue.length ? response[0].revenue[0].revenue : 0
    },
    setSearchClientString(string) {
      this.searchClientString = string
    },
    setClient(client) {
      this.selectedClient = client
    },
    clearClient() {
      this.searchClientString = ""
      this.selectedClient = {}
    },
    setSearchItemString(string) {
      this.searchItemString = string
    },
    setItem(item) {
      this.selectedItem = item
    },
    clearItem() {
      this.searchItemString = ""
      this.selectedItem = {}
    },
    reset() {
      this.fromDate = new Date(Date.now()).toISOString().split("T")[0]
      this.toDate = new Date(Date.now()).toISOString().split("T")[0]
      this.fromDateFormatted = this.formatDate(
        new Date().toISOString().split("T")[0]
      )
      this.toDateFormatted = this.formatDate(
        new Date().toISOString().split("T")[0]
      )
      this.year = null
      this.month = null
      this.quarter = null
      this.selectedCategory = ""
      this.selectedClient = {}
      this.selectedItem = {}
      this.searchClientString = ""
      this.searchItemString = ""
      this.searchLotNumber = ""
      this.items = []
      this.itemsLength = 0
      this.options = {
        itemsPerPage: 50,
        page: 1,
        sortBy: ["date"],
        sortDesc: [true]
      }
    },
    async updateOrderItem(item, amount2, outOfStock) {
      try {
        if (this.thisOrder._id == undefined) throw "Geen order geselecteerd"
        if (this.thisOrder.status == 3) throw "Order is gefactureerd"
        if (this.thisClient._id == undefined) throw "Geen klant geselecteerd"
        if (this.thisItem._id == undefined) throw "Geen artikel geselecteerd"
        if (outOfStock) {
          item.amount = 0
          item.status = 2
        }
        this.thisItem.amount = amount2
        let amount = Big(amount2)
        let price = Big(item.price)
        let unitAmount = Big(item.unitAmount)
        let packages = Big()
        let crates = item.crates
        let totalWeight = Big()
        let netSubtotal = Big()
        let totalVAT = Big()
        let grossSubtotal = Big()
        let itemVAT = Big(item.VAT).div(100)
        packages =
          item.unit == "kg"
            ? amount.div(item.packageAmount).round(undefined, 3)
            : amount
        crates = item.packaging == "Crate" && parseInt(item.unitAmount) > 5 ? amount.toNumber() : 0
        totalWeight =
          item.unit == "kg" ? amount : amount.times(item.unitAmount).round(2)
        netSubtotal =
          item.unit == "kg"
            ? amount.times(price).round(2)
            : amount
              .times(price)
              .times(unitAmount)
              .round(2)
        if (this.selectedClient.includeVAT)
          netSubtotal = netSubtotal.div(
            Big(item.VAT)
              .plus(100)
              .div(100)
          )
        totalVAT = netSubtotal.times(itemVAT)
        grossSubtotal = netSubtotal.plus(totalVAT)
        item.packages = packages.toNumber()
        item.crates = crates
        item.totalWeight = totalWeight.toNumber()
        item.netSubtotal = netSubtotal.toNumber()
        item.totalVAT = totalVAT.toNumber()
        item.grossSubtotal = grossSubtotal.toNumber()
        item.totalCost =
          item.unit == "kg"
            ? item.amount * item.cost
            : item.amount * item.unitAmount * item.cost
        item.totalProfit = item.netSubtotal - item.totalCost
        let order = this.thisOrder
        let index = order.items.findIndex(i => i._id == item._id)
        if (index > -1) {
          Object.assign(order.items[index], item)
        }
        let orderVAT
        if (order.client.export) {
          orderVAT = [{ name: "Geen", amount: 0, total: this.orderNetTotal }]
        } else {
          orderVAT = JSON.parse(JSON.stringify(this.$store.state.settings.VAT))
          for (let VAT of orderVAT) {
            VAT.total = order.items
              .filter(item => VAT.amount === item.VAT)
              .reduce((acc, cur) => {
                if (cur.VAT == 0)
                  return (acc * 100 + parseFloat(cur.netSubtotal) * 100) / 100
                else return (acc * 100 + parseFloat(cur.totalVAT) * 100) / 100
              }, 0)
          }
        }
        let VAT = orderVAT.filter(VAT => parseFloat(VAT.total) != 0)
        order.VAT = VAT
        let payments = order.payments
        let totalPaid = payments.reduce((acc, cur) => acc + cur.amount, 0)
        let totalWeight2 = order.items.reduce(
          (acc, cur) => acc + cur.totalWeight,
          0
        )
        order.netTotal = order.items.reduce(
          (acc, cur) => (acc * 100 + parseFloat(cur.netSubtotal) * 100) / 100,
          0
        )
        order.totalVAT = order.items.reduce(
          (acc, cur) => (acc * 100 + parseFloat(cur.totalVAT) * 100) / 100,
          0
        )

        order.grossTotal = (order.netTotal * 100 + order.totalVAT * 100) / 100
        order.totalWeight = totalWeight2
        order.credit = totalPaid
        order.debit = order.grossTotal - totalPaid
        order.totalPackages = order.items.reduce(
          (acc, cur) => (acc * 100 + parseFloat(cur.packages) * 100) / 100,
          0
        )
        order.totalCrates = order.items.reduce(
          (acc, cur) => (acc * 100 + parseFloat(cur.crates) * 100) / 100,
          0
        )
        let res = await fetchPOST("updateOrderItem", {
          order: order,
          item: item
        })
        if (res.result.nModified == 0) throw "Artikel niet gewijzigd"
        this.$store.commit("updateOrderItem", { order: order, item: item })
        // if (!outOfStock) this.orderStatus(this.order)
        successHandler("Artikel gewijzigd")
        this.fetchItemsSalesHistory()
        this.closeEditItemModal()
      } catch (e) {
        errorHandler(e, e)
      }
    },
    async deleteOrderItem(ids) {
      try {
        let order = this.fetchOrderById(ids.order_id)
        let index = order.items.findIndex(i => i._id == ids.item_id)
        if (index > -1) {
          await this.updateOrderItem(order.items[index], 0, true)
          order.items.splice(index, 1)
        }
      } catch (e) {
        errorHandler(e, "Artikel niet verwijderd")
      }
    },
    closeEditItemModal() {
      this.thisOrder = {}
      this.thisClient = {}
      this.thisItem = { name: "", amount: 0 }
      this.editItemModal = false
    },
    async findOrder(item) {
      try {
        let order = await this.fetchOrderById(item.order_id)
        console.log(order);
        this.thisOrder = order
        this.thisClient = item.client
        this.thisItem = {
          _id: item.item_id,
          itemNumber: item.itemNumber,
          name: item.name,
          description: item.description,
          amount: item.amount,
          unit: item.unit,
          unitAmount: item.unitAmount,
          price: item.price,
          VAT: item.VAT,
          netSubtotal: item.netSubtotal,
          grossSubtotal: item.grossSubtotal,
          packaging: item.packaging,
          packageAmount: item.packageAmount,
          crates: item.crates,
          lotNumber: item.lotNumber,
          commodityCode: item.commodityCode,
          status: item.status,
          cost: item.cost,
          order_id: item.order_id
        }
        return order;
        // this.editItemModal = true
      } catch (e) {
        errorHandler(e, "Order niet gevonden")
      }
    },
    async fetchOrderById(id) {
      let response = await fetchGET("fetchOrderById", { _id: id })
      return response
    },
    async fetchInvoiceByNumber(invoiceNumber) {
      let response = await fetchGET("fetchInvoiceByNumber", {
        invoiceNumber, options: {
          itemsPerPage: 50,
          page: 1,
          sortBy: ["invoiceNumber"],
          sortDesc: [true]
        },
      })
      console.log('response', response)
      return response
    },
    selectClient(client) {
      this.clearItem()
      this.searchClientString = client.name
      this.selectedClient = client
      this.fetchItemsSalesHistory()
    },
    selectItem(item) {
      this.clearClient()
      this.searchItemString = item.name
      this.selectedItem = item
      this.fetchItemsSalesHistory()
    },
    async downloadOrder(item) {
      try {
        console.log(item);
        const order = await this.findOrder(item)
        console.log(order);
        downloadOrder(order, { includePrices: false })
        if (order.invoiceNumber) {
          const invoice = await this.fetchInvoiceByNumber(order.invoiceNumber)
          downloadInvoice(invoice, {
            options: {
              itemsPerPage: 50,
              page: 1,
              sortBy: ["invoiceNumber"],
              sortDesc: [true]
            },
          })
        }
      }
      catch (e) {
        errorHandler(e, "Order niet gevonden")
      }
    },
  },
  computed: {
    categories() {
      return this.$store.state.settings.categories
    }
  },
  components: {
    datePicker,
    searchClient,
    searchItem,
    editItemModal
  }
}
</script>
<style scoped>
.header-input {
  padding: 5px;
  max-width: 300px;
}

.header-input-small {
  padding: 0 5px;
  max-width: 200px;
}

button {
  width: 100px;
  height: 50px;
  background: darkblue;
  color: white;
  margin-bottom: 30px;
}

.full-width {
  width: 100%;
}

.stats {
  padding: 5px;
  font-size: 1.3em;
  font-weight: bold;
}
</style>